/**
 *
 * LoginModal
 *
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RegistrationModal from './index';

const LoginModal = ({ modal }) => (
  <RegistrationModal type="login" modalPathname="/sign-in" modal={modal} />
);

LoginModal.propTypes = {
  modal: PropTypes.object,
};

const mapStateToProps = ({ display, modals }) => ({
  display,
  modal: modals.registrationModal,
});

export default connect(mapStateToProps)(LoginModal);
